import Box from "@mui/material/Box";
import Typography, { TypographyProps } from "@mui/material/Typography";
import { useTheme } from "@mui/material";

const Heading: React.FC<{
  children: React.ReactNode;
  typographyProps?: TypographyProps;
}> = ({ children, typographyProps }) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography
        variant="h3"
        color="inherit"
        {...(typographyProps || {})}
        sx={{
          fontWeight: 700,
          fontSize: { xs: 18, md: 28 },
          [theme.breakpoints.down("md")]: {
            textAlign: "center",
          },
          ...(typographyProps?.sx || {}),
        }}
      >
        {children}
      </Typography>
    </Box>
  );
};

export default Heading;
